/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCards = /* GraphQL */ `query GetCards {
  getCards {
    ... on GetCardsResponseSuccess {
      userCards {
        id
        brand
        last4
        expMonth
        expYear
        __typename
      }
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedQuery<any, APITypes.GetCardsQuery>;
export const getJWT = /* GraphQL */ `query GetJWT {
  getJWT {
    ... on GetJWTResponseSuccess {
      token
      code
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedQuery<any, APITypes.GetJWTQuery>;
export const getBusiness = /* GraphQL */ `query GetBusiness($id: ID!) {
  getBusiness(id: $id) {
    id
    name
    locations {
      nextToken
      __typename
    }
    corporate {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBusinessQueryVariables, APITypes.GetBusinessQuery>;
export const listBusinesses = /* GraphQL */ `query ListBusinesses(
  $id: ID
  $filter: ModelBusinessFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBusinesses(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListBusinessesQueryVariables, APITypes.ListBusinessesQuery>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    type
    coin
    totalCoin
    stripeCustomerId
    association {
      nextToken
      __typename
    }
    orders {
      nextToken
      __typename
    }
    firstName
    businessName
    taxCode
    sdi
    lastName
    fiscalCode
    address {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    gender
    dob {
      day
      month
      year
      __typename
    }
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $id: ID
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      gender
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const usersByEmail = /* GraphQL */ `query UsersByEmail(
  $email: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      gender
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.UsersByEmailQueryVariables, APITypes.UsersByEmailQuery>;
export const getAssociated = /* GraphQL */ `query GetAssociated($id: ID!) {
  getAssociated(id: $id) {
    id
    userId
    corporateID
    userEmail
    user {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      gender
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    presences {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAssociatedQueryVariables, APITypes.GetAssociatedQuery>;
export const listAssociateds = /* GraphQL */ `query ListAssociateds(
  $id: ID
  $filter: ModelAssociatedFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAssociateds(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      userId
      corporateID
      userEmail
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListAssociatedsQueryVariables, APITypes.ListAssociatedsQuery>;
export const associatedByUser = /* GraphQL */ `query AssociatedByUser(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAssociatedFilterInput
  $limit: Int
  $nextToken: String
) {
  associatedByUser(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      corporateID
      userEmail
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.AssociatedByUserQueryVariables, APITypes.AssociatedByUserQuery>;
export const associatedByCorporate = /* GraphQL */ `query AssociatedByCorporate(
  $corporateID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAssociatedFilterInput
  $limit: Int
  $nextToken: String
) {
  associatedByCorporate(
    corporateID: $corporateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      corporateID
      userEmail
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AssociatedByCorporateQueryVariables,
  APITypes.AssociatedByCorporateQuery
>;
export const associatedByEmail = /* GraphQL */ `query AssociatedByEmail(
  $userEmail: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAssociatedFilterInput
  $limit: Int
  $nextToken: String
) {
  associatedByEmail(
    userEmail: $userEmail
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      corporateID
      userEmail
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.AssociatedByEmailQueryVariables, APITypes.AssociatedByEmailQuery>;
export const getCorporate = /* GraphQL */ `query GetCorporate($id: ID!) {
  getCorporate(id: $id) {
    id
    businessCorporateId
    name
    associates {
      nextToken
      __typename
    }
    associatedLocations {
      nextToken
      __typename
    }
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    presences {
      nextToken
      __typename
    }
    stripeInfo {
      stripeCostumer
      stripeAccount
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCorporateQueryVariables, APITypes.GetCorporateQuery>;
export const listCorporates = /* GraphQL */ `query ListCorporates(
  $id: ID
  $filter: ModelCorporateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCorporates(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCorporatesQueryVariables, APITypes.ListCorporatesQuery>;
export const corporateByBusiness = /* GraphQL */ `query CorporateByBusiness(
  $businessCorporateId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCorporateFilterInput
  $limit: Int
  $nextToken: String
) {
  corporateByBusiness(
    businessCorporateId: $businessCorporateId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.CorporateByBusinessQueryVariables, APITypes.CorporateByBusinessQuery>;
export const getLocation = /* GraphQL */ `query GetLocation($id: ID!) {
  getLocation(id: $id) {
    id
    name
    images
    logo
    bio
    bioTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    gallery {
      image
      name
      __typename
    }
    posts {
      nextToken
      __typename
    }
    stripeInfo {
      stripeCostumer
      stripeAccount
      __typename
    }
    invoiceInfo {
      taxCode
      broadcastFormat
      businessName
      taxRegime
      email
      sdi
      nextInvoiceNumber
      __typename
    }
    openingDay {
      __typename
    }
    deliverectId
    status
    timeZone
    menu {
      nextToken
      __typename
    }
    menuSortOrder
    floors {
      nextToken
      __typename
    }
    floorsSortOrder
    credit
    selfOrdering
    selfPayment
    unauthenticatedOrdering
    defaultOrderType
    externalLinks {
      link
      label
      icon
      __typename
    }
    template
    color
    showCurrency
    showWineList
    address {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    phoneNumber
    social {
      link
      label
      icon
      __typename
    }
    email
    languageDefault
    languagesEnabled
    translationsInProgress
    coverPrice
    coverMainId
    coverMain {
      id
      businessId
      locationId
      name
      description
      shortName
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      isCover
      priceType
      subProductSortOrder
      stock
      understock
      erpColor
      erpCode
      erpImport
      vatRate
      createdAt
      updatedAt
      locationCoverAdditionalId
      __typename
    }
    coverAdditional {
      nextToken
      __typename
    }
    erp
    erpMenuId
    erpMenu {
      id
      businessId
      menuLocationId
      name
      description
      shortName
      dense
      type
      nestedModifiers
      image
      currency
      disabled
      wineList
      onlyAssociated
      categorySortOrder
      isErp
      createdAt
      updatedAt
      __typename
    }
    deliveryCost
    generalVatRate
    businessLocationsId
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    associatedCorporate {
      nextToken
      __typename
    }
    presences {
      nextToken
      __typename
    }
    bankDetails {
      accountHolder
      iban
      bic
      __typename
    }
    departments {
      nextToken
      __typename
    }
    departmentsSortOrder
    invoicesAmount
    invoicesCount
    receiptsAmount
    receiptsCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLocationQueryVariables, APITypes.GetLocationQuery>;
export const listLocations = /* GraphQL */ `query ListLocations(
  $id: ID
  $filter: ModelLocationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listLocations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      floorsSortOrder
      credit
      selfOrdering
      selfPayment
      unauthenticatedOrdering
      defaultOrderType
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      coverMainId
      erp
      erpMenuId
      deliveryCost
      generalVatRate
      businessLocationsId
      departmentsSortOrder
      invoicesAmount
      invoicesCount
      receiptsAmount
      receiptsCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListLocationsQueryVariables, APITypes.ListLocationsQuery>;
export const locationByDeliverectId = /* GraphQL */ `query LocationByDeliverectId(
  $deliverectId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  locationByDeliverectId(
    deliverectId: $deliverectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      floorsSortOrder
      credit
      selfOrdering
      selfPayment
      unauthenticatedOrdering
      defaultOrderType
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      coverMainId
      erp
      erpMenuId
      deliveryCost
      generalVatRate
      businessLocationsId
      departmentsSortOrder
      invoicesAmount
      invoicesCount
      receiptsAmount
      receiptsCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LocationByDeliverectIdQueryVariables,
  APITypes.LocationByDeliverectIdQuery
>;
export const locationByBusiness = /* GraphQL */ `query LocationByBusiness(
  $businessLocationsId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  locationByBusiness(
    businessLocationsId: $businessLocationsId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      floorsSortOrder
      credit
      selfOrdering
      selfPayment
      unauthenticatedOrdering
      defaultOrderType
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      coverMainId
      erp
      erpMenuId
      deliveryCost
      generalVatRate
      businessLocationsId
      departmentsSortOrder
      invoicesAmount
      invoicesCount
      receiptsAmount
      receiptsCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.LocationByBusinessQueryVariables, APITypes.LocationByBusinessQuery>;
export const getPost = /* GraphQL */ `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    image
    name
    nameTranslations {
      language
      body
      __typename
    }
    userId
    userName
    postLocationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPostQueryVariables, APITypes.GetPostQuery>;
export const listPosts = /* GraphQL */ `query ListPosts(
  $id: ID
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPosts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      image
      name
      userId
      userName
      postLocationId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPostsQueryVariables, APITypes.ListPostsQuery>;
export const postByLocation = /* GraphQL */ `query PostByLocation(
  $postLocationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postByLocation(
    postLocationId: $postLocationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      image
      name
      userId
      userName
      postLocationId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.PostByLocationQueryVariables, APITypes.PostByLocationQuery>;
export const getCorporateLocations = /* GraphQL */ `query GetCorporateLocations($id: ID!) {
  getCorporateLocations(id: $id) {
    id
    locationID
    corporateID
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      floorsSortOrder
      credit
      selfOrdering
      selfPayment
      unauthenticatedOrdering
      defaultOrderType
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      coverMainId
      erp
      erpMenuId
      deliveryCost
      generalVatRate
      businessLocationsId
      departmentsSortOrder
      invoicesAmount
      invoicesCount
      receiptsAmount
      receiptsCount
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCorporateLocationsQueryVariables,
  APITypes.GetCorporateLocationsQuery
>;
export const listCorporateLocations = /* GraphQL */ `query ListCorporateLocations(
  $id: ID
  $filter: ModelCorporateLocationsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCorporateLocations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      locationID
      corporateID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCorporateLocationsQueryVariables,
  APITypes.ListCorporateLocationsQuery
>;
export const corporateLocationsByLocation = /* GraphQL */ `query CorporateLocationsByLocation(
  $locationID: ID!
  $corporateID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCorporateLocationsFilterInput
  $limit: Int
  $nextToken: String
) {
  corporateLocationsByLocation(
    locationID: $locationID
    corporateID: $corporateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      locationID
      corporateID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CorporateLocationsByLocationQueryVariables,
  APITypes.CorporateLocationsByLocationQuery
>;
export const corporateLocationsByCorporate = /* GraphQL */ `query CorporateLocationsByCorporate(
  $corporateID: ID!
  $locationID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCorporateLocationsFilterInput
  $limit: Int
  $nextToken: String
) {
  corporateLocationsByCorporate(
    corporateID: $corporateID
    locationID: $locationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      locationID
      corporateID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CorporateLocationsByCorporateQueryVariables,
  APITypes.CorporateLocationsByCorporateQuery
>;
export const getAssociatedPresence = /* GraphQL */ `query GetAssociatedPresence($id: ID!) {
  getAssociatedPresence(id: $id) {
    id
    associatedID
    locationID
    corporateID
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      floorsSortOrder
      credit
      selfOrdering
      selfPayment
      unauthenticatedOrdering
      defaultOrderType
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      coverMainId
      erp
      erpMenuId
      deliveryCost
      generalVatRate
      businessLocationsId
      departmentsSortOrder
      invoicesAmount
      invoicesCount
      receiptsAmount
      receiptsCount
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    associated {
      id
      userId
      corporateID
      userEmail
      status
      createdAt
      updatedAt
      __typename
    }
    createdAt
    paid
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssociatedPresenceQueryVariables,
  APITypes.GetAssociatedPresenceQuery
>;
export const listAssociatedPresences = /* GraphQL */ `query ListAssociatedPresences(
  $id: ID
  $filter: ModelAssociatedPresenceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAssociatedPresences(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      associatedID
      locationID
      corporateID
      createdAt
      paid
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAssociatedPresencesQueryVariables,
  APITypes.ListAssociatedPresencesQuery
>;
export const associatedPresenceByAssociated = /* GraphQL */ `query AssociatedPresenceByAssociated(
  $associatedID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAssociatedPresenceFilterInput
  $limit: Int
  $nextToken: String
) {
  associatedPresenceByAssociated(
    associatedID: $associatedID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      associatedID
      locationID
      corporateID
      createdAt
      paid
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AssociatedPresenceByAssociatedQueryVariables,
  APITypes.AssociatedPresenceByAssociatedQuery
>;
export const associatedPresenceByLocation = /* GraphQL */ `query AssociatedPresenceByLocation(
  $locationID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAssociatedPresenceFilterInput
  $limit: Int
  $nextToken: String
) {
  associatedPresenceByLocation(
    locationID: $locationID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      associatedID
      locationID
      corporateID
      createdAt
      paid
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AssociatedPresenceByLocationQueryVariables,
  APITypes.AssociatedPresenceByLocationQuery
>;
export const associatedPresenceByCorporate = /* GraphQL */ `query AssociatedPresenceByCorporate(
  $corporateID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAssociatedPresenceFilterInput
  $limit: Int
  $nextToken: String
) {
  associatedPresenceByCorporate(
    corporateID: $corporateID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      associatedID
      locationID
      corporateID
      createdAt
      paid
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AssociatedPresenceByCorporateQueryVariables,
  APITypes.AssociatedPresenceByCorporateQuery
>;
export const getMenu = /* GraphQL */ `query GetMenu($id: ID!) {
  getMenu(id: $id) {
    id
    businessId
    menuLocationId
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      floorsSortOrder
      credit
      selfOrdering
      selfPayment
      unauthenticatedOrdering
      defaultOrderType
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      coverMainId
      erp
      erpMenuId
      deliveryCost
      generalVatRate
      businessLocationsId
      departmentsSortOrder
      invoicesAmount
      invoicesCount
      receiptsAmount
      receiptsCount
      createdAt
      updatedAt
      __typename
    }
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    shortName
    dense
    type
    nestedModifiers
    image
    currency
    availabilities {
      dayOfWeek
      startTime
      endTime
      __typename
    }
    disabled
    wineList
    onlyAssociated
    categorySortOrder
    categories {
      nextToken
      __typename
    }
    isErp
    rotation {
      type
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMenuQueryVariables, APITypes.GetMenuQuery>;
export const listMenus = /* GraphQL */ `query ListMenus(
  $id: ID
  $filter: ModelMenuFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMenus(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      businessId
      menuLocationId
      name
      description
      shortName
      dense
      type
      nestedModifiers
      image
      currency
      disabled
      wineList
      onlyAssociated
      categorySortOrder
      isErp
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMenusQueryVariables, APITypes.ListMenusQuery>;
export const menuByBusiness = /* GraphQL */ `query MenuByBusiness(
  $businessId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMenuFilterInput
  $limit: Int
  $nextToken: String
) {
  menuByBusiness(
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      menuLocationId
      name
      description
      shortName
      dense
      type
      nestedModifiers
      image
      currency
      disabled
      wineList
      onlyAssociated
      categorySortOrder
      isErp
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.MenuByBusinessQueryVariables, APITypes.MenuByBusinessQuery>;
export const menuByLocation = /* GraphQL */ `query MenuByLocation(
  $menuLocationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMenuFilterInput
  $limit: Int
  $nextToken: String
) {
  menuByLocation(
    menuLocationId: $menuLocationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      menuLocationId
      name
      description
      shortName
      dense
      type
      nestedModifiers
      image
      currency
      disabled
      wineList
      onlyAssociated
      categorySortOrder
      isErp
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.MenuByLocationQueryVariables, APITypes.MenuByLocationQuery>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    businessId
    menuCategoriesId
    locationId
    menu {
      id
      businessId
      menuLocationId
      name
      description
      shortName
      dense
      type
      nestedModifiers
      image
      currency
      disabled
      wineList
      onlyAssociated
      categorySortOrder
      isErp
      createdAt
      updatedAt
      __typename
    }
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    shortName
    dense
    image
    posCategoryType
    posCategoryId
    posLocationId
    availabilities {
      dayOfWeek
      startTime
      endTime
      __typename
    }
    level
    sortedChannelProductIds
    subProductSortOrder
    disabled
    wineList
    subCategories {
      nextToken
      __typename
    }
    products {
      nextToken
      __typename
    }
    isErp
    rotation {
      type
      __typename
    }
    erpColor
    createdAt
    updatedAt
    categorySubCategoriesId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCategoryQueryVariables, APITypes.GetCategoryQuery>;
export const listCategories = /* GraphQL */ `query ListCategories(
  $id: ID
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCategories(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      businessId
      menuCategoriesId
      locationId
      name
      description
      shortName
      dense
      image
      posCategoryType
      posCategoryId
      posLocationId
      level
      sortedChannelProductIds
      subProductSortOrder
      disabled
      wineList
      isErp
      erpColor
      createdAt
      updatedAt
      categorySubCategoriesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCategoriesQueryVariables, APITypes.ListCategoriesQuery>;
export const categoryByBusiness = /* GraphQL */ `query CategoryByBusiness(
  $businessId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoryByBusiness(
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      menuCategoriesId
      locationId
      name
      description
      shortName
      dense
      image
      posCategoryType
      posCategoryId
      posLocationId
      level
      sortedChannelProductIds
      subProductSortOrder
      disabled
      wineList
      isErp
      erpColor
      createdAt
      updatedAt
      categorySubCategoriesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.CategoryByBusinessQueryVariables, APITypes.CategoryByBusinessQuery>;
export const categoryByMenu = /* GraphQL */ `query CategoryByMenu(
  $menuCategoriesId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoryByMenu(
    menuCategoriesId: $menuCategoriesId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      menuCategoriesId
      locationId
      name
      description
      shortName
      dense
      image
      posCategoryType
      posCategoryId
      posLocationId
      level
      sortedChannelProductIds
      subProductSortOrder
      disabled
      wineList
      isErp
      erpColor
      createdAt
      updatedAt
      categorySubCategoriesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.CategoryByMenuQueryVariables, APITypes.CategoryByMenuQuery>;
export const categoryByLocation = /* GraphQL */ `query CategoryByLocation(
  $locationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoryByLocation(
    locationId: $locationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      menuCategoriesId
      locationId
      name
      description
      shortName
      dense
      image
      posCategoryType
      posCategoryId
      posLocationId
      level
      sortedChannelProductIds
      subProductSortOrder
      disabled
      wineList
      isErp
      erpColor
      createdAt
      updatedAt
      categorySubCategoriesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.CategoryByLocationQueryVariables, APITypes.CategoryByLocationQuery>;
export const getProduct = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    businessId
    categories {
      nextToken
      __typename
    }
    locationId
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    shortName
    dense
    image
    capacityUsages
    deliveryTax
    eatInTax
    takeawayTax
    max
    min
    isVariant
    multiMax
    multiply
    plu
    disabled
    snoozeStart
    snoozeEnd
    price
    posCategoryIds
    posProductCategoryId
    posProductId
    productTags
    productType
    isWine
    isCover
    wineInfo {
      caption
      year
      perfectFor
      typology
      country
      region
      alcoholPercentage
      servingTemperature
      color
      perfume
      taste
      producer
      productionMethod
      __typename
    }
    priceType
    subProductSortOrder
    subProducts {
      nextToken
      __typename
    }
    rotation {
      type
      __typename
    }
    stock
    understock
    erpColor
    erpCode
    erpImport
    vatRate
    createdAt
    updatedAt
    locationCoverAdditionalId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetProductQueryVariables, APITypes.GetProductQuery>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $id: ID
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listProducts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      businessId
      locationId
      name
      description
      shortName
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      isCover
      priceType
      subProductSortOrder
      stock
      understock
      erpColor
      erpCode
      erpImport
      vatRate
      createdAt
      updatedAt
      locationCoverAdditionalId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListProductsQueryVariables, APITypes.ListProductsQuery>;
export const productByBusiness = /* GraphQL */ `query ProductByBusiness(
  $businessId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productByBusiness(
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      name
      description
      shortName
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      isCover
      priceType
      subProductSortOrder
      stock
      understock
      erpColor
      erpCode
      erpImport
      vatRate
      createdAt
      updatedAt
      locationCoverAdditionalId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ProductByBusinessQueryVariables, APITypes.ProductByBusinessQuery>;
export const productByLocation = /* GraphQL */ `query ProductByLocation(
  $locationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productByLocation(
    locationId: $locationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      name
      description
      shortName
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      isCover
      priceType
      subProductSortOrder
      stock
      understock
      erpColor
      erpCode
      erpImport
      vatRate
      createdAt
      updatedAt
      locationCoverAdditionalId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ProductByLocationQueryVariables, APITypes.ProductByLocationQuery>;
export const productByPlu = /* GraphQL */ `query ProductByPlu(
  $plu: String!
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productByPlu(
    plu: $plu
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      name
      description
      shortName
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      isCover
      priceType
      subProductSortOrder
      stock
      understock
      erpColor
      erpCode
      erpImport
      vatRate
      createdAt
      updatedAt
      locationCoverAdditionalId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ProductByPluQueryVariables, APITypes.ProductByPluQuery>;
export const getCategoriesProducts = /* GraphQL */ `query GetCategoriesProducts($id: ID!) {
  getCategoriesProducts(id: $id) {
    id
    locationId
    productID
    categoryID
    product {
      id
      businessId
      locationId
      name
      description
      shortName
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      isCover
      priceType
      subProductSortOrder
      stock
      understock
      erpColor
      erpCode
      erpImport
      vatRate
      createdAt
      updatedAt
      locationCoverAdditionalId
      __typename
    }
    category {
      id
      businessId
      menuCategoriesId
      locationId
      name
      description
      shortName
      dense
      image
      posCategoryType
      posCategoryId
      posLocationId
      level
      sortedChannelProductIds
      subProductSortOrder
      disabled
      wineList
      isErp
      erpColor
      createdAt
      updatedAt
      categorySubCategoriesId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoriesProductsQueryVariables,
  APITypes.GetCategoriesProductsQuery
>;
export const listCategoriesProducts = /* GraphQL */ `query ListCategoriesProducts(
  $id: ID
  $filter: ModelCategoriesProductsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCategoriesProducts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      locationId
      productID
      categoryID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesProductsQueryVariables,
  APITypes.ListCategoriesProductsQuery
>;
export const categoriesProductsByLocation = /* GraphQL */ `query CategoriesProductsByLocation(
  $locationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCategoriesProductsFilterInput
  $limit: Int
  $nextToken: String
) {
  categoriesProductsByLocation(
    locationId: $locationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      locationId
      productID
      categoryID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoriesProductsByLocationQueryVariables,
  APITypes.CategoriesProductsByLocationQuery
>;
export const getSubProducts = /* GraphQL */ `query GetSubProducts($id: ID!) {
  getSubProducts(id: $id) {
    id
    businessId
    locationId
    productId
    product {
      id
      businessId
      locationId
      name
      description
      shortName
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      isCover
      priceType
      subProductSortOrder
      stock
      understock
      erpColor
      erpCode
      erpImport
      vatRate
      createdAt
      updatedAt
      locationCoverAdditionalId
      __typename
    }
    products {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSubProductsQueryVariables, APITypes.GetSubProductsQuery>;
export const listSubProducts = /* GraphQL */ `query ListSubProducts(
  $id: ID
  $filter: ModelSubProductsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSubProducts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      businessId
      locationId
      productId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSubProductsQueryVariables, APITypes.ListSubProductsQuery>;
export const subProductByBusiness = /* GraphQL */ `query SubProductByBusiness(
  $businessId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSubProductsFilterInput
  $limit: Int
  $nextToken: String
) {
  subProductByBusiness(
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      productId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SubProductByBusinessQueryVariables,
  APITypes.SubProductByBusinessQuery
>;
export const subProductByLocation = /* GraphQL */ `query SubProductByLocation(
  $locationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSubProductsFilterInput
  $limit: Int
  $nextToken: String
) {
  subProductByLocation(
    locationId: $locationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      productId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SubProductByLocationQueryVariables,
  APITypes.SubProductByLocationQuery
>;
export const getProductsSubProducts = /* GraphQL */ `query GetProductsSubProducts($id: ID!) {
  getProductsSubProducts(id: $id) {
    id
    locationId
    productID
    subProductsID
    product {
      id
      businessId
      locationId
      name
      description
      shortName
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      isCover
      priceType
      subProductSortOrder
      stock
      understock
      erpColor
      erpCode
      erpImport
      vatRate
      createdAt
      updatedAt
      locationCoverAdditionalId
      __typename
    }
    subProducts {
      id
      businessId
      locationId
      productId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductsSubProductsQueryVariables,
  APITypes.GetProductsSubProductsQuery
>;
export const listProductsSubProducts = /* GraphQL */ `query ListProductsSubProducts(
  $id: ID
  $filter: ModelProductsSubProductsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listProductsSubProducts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      locationId
      productID
      subProductsID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsSubProductsQueryVariables,
  APITypes.ListProductsSubProductsQuery
>;
export const productsSubProductsByLocation = /* GraphQL */ `query ProductsSubProductsByLocation(
  $locationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProductsSubProductsFilterInput
  $limit: Int
  $nextToken: String
) {
  productsSubProductsByLocation(
    locationId: $locationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      locationId
      productID
      subProductsID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductsSubProductsByLocationQueryVariables,
  APITypes.ProductsSubProductsByLocationQuery
>;
export const getFloor = /* GraphQL */ `query GetFloor($id: ID!) {
  getFloor(id: $id) {
    id
    businessId
    locationFloorsId
    name
    tables {
      nextToken
      __typename
    }
    width
    height
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFloorQueryVariables, APITypes.GetFloorQuery>;
export const listFloors = /* GraphQL */ `query ListFloors(
  $id: ID
  $filter: ModelFloorFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listFloors(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      businessId
      locationFloorsId
      name
      width
      height
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFloorsQueryVariables, APITypes.ListFloorsQuery>;
export const floorByBusiness = /* GraphQL */ `query FloorByBusiness(
  $businessId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFloorFilterInput
  $limit: Int
  $nextToken: String
) {
  floorByBusiness(
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationFloorsId
      name
      width
      height
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.FloorByBusinessQueryVariables, APITypes.FloorByBusinessQuery>;
export const floorByLocation = /* GraphQL */ `query FloorByLocation(
  $locationFloorsId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFloorFilterInput
  $limit: Int
  $nextToken: String
) {
  floorByLocation(
    locationFloorsId: $locationFloorsId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationFloorsId
      name
      width
      height
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.FloorByLocationQueryVariables, APITypes.FloorByLocationQuery>;
export const getTable = /* GraphQL */ `query GetTable($id: ID!) {
  getTable(id: $id) {
    id
    businessId
    locationId
    floorTablesId
    floor {
      id
      businessId
      locationFloorsId
      name
      width
      height
      createdAt
      updatedAt
      __typename
    }
    name
    seats
    erpCode
    activeOrder {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      createdAt
      updatedAt
      deliveryCost
      phoneNumber
      instantPayment
      erpPrintMode
      orderTableId
      __typename
    }
    coordinates {
      x
      y
      __typename
    }
    subTables {
      nextToken
      __typename
    }
    busy
    createdAt
    updatedAt
    tableSubTablesId
    tableActiveOrderId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTableQueryVariables, APITypes.GetTableQuery>;
export const listTables = /* GraphQL */ `query ListTables(
  $id: ID
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTables(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      businessId
      locationId
      floorTablesId
      name
      seats
      erpCode
      busy
      createdAt
      updatedAt
      tableSubTablesId
      tableActiveOrderId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTablesQueryVariables, APITypes.ListTablesQuery>;
export const tableByBusiness = /* GraphQL */ `query TableByBusiness(
  $businessId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
) {
  tableByBusiness(
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      floorTablesId
      name
      seats
      erpCode
      busy
      createdAt
      updatedAt
      tableSubTablesId
      tableActiveOrderId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.TableByBusinessQueryVariables, APITypes.TableByBusinessQuery>;
export const tableByLocation = /* GraphQL */ `query TableByLocation(
  $locationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
) {
  tableByLocation(
    locationId: $locationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      floorTablesId
      name
      seats
      erpCode
      busy
      createdAt
      updatedAt
      tableSubTablesId
      tableActiveOrderId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.TableByLocationQueryVariables, APITypes.TableByLocationQuery>;
export const tableByErpCode = /* GraphQL */ `query TableByErpCode(
  $erpCode: String!
  $locationId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
) {
  tableByErpCode(
    erpCode: $erpCode
    locationId: $locationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      floorTablesId
      name
      seats
      erpCode
      busy
      createdAt
      updatedAt
      tableSubTablesId
      tableActiveOrderId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.TableByErpCodeQueryVariables, APITypes.TableByErpCodeQuery>;
export const getOrderItem = /* GraphQL */ `query GetOrderItem($id: ID!) {
  getOrderItem(id: $id) {
    id
    locationId
    plu
    name
    shortName
    quantity
    quantityPaid
    price
    total
    type
    payment
    completed
    subItemsSortOrder
    createdAt
    updatedAt
    erpCode
    deleted
    edited
    vatRate
    product {
      id
      businessId
      locationId
      name
      description
      shortName
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      isCover
      priceType
      subProductSortOrder
      stock
      understock
      erpColor
      erpCode
      erpImport
      vatRate
      createdAt
      updatedAt
      locationCoverAdditionalId
      __typename
    }
    subItems {
      nextToken
      __typename
    }
    orderItemSubItemsId
    orderOrderItemsId
    orderItemProductId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderItemQueryVariables, APITypes.GetOrderItemQuery>;
export const listOrderItems = /* GraphQL */ `query ListOrderItems(
  $id: ID
  $filter: ModelOrderItemFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrderItems(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      locationId
      plu
      name
      shortName
      quantity
      quantityPaid
      price
      total
      type
      payment
      completed
      subItemsSortOrder
      createdAt
      updatedAt
      erpCode
      deleted
      edited
      vatRate
      orderItemSubItemsId
      orderOrderItemsId
      orderItemProductId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListOrderItemsQueryVariables, APITypes.ListOrderItemsQuery>;
export const OrderItemByLocation = /* GraphQL */ `query OrderItemByLocation(
  $locationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOrderItemFilterInput
  $limit: Int
  $nextToken: String
) {
  OrderItemByLocation(
    locationId: $locationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      locationId
      plu
      name
      shortName
      quantity
      quantityPaid
      price
      total
      type
      payment
      completed
      subItemsSortOrder
      createdAt
      updatedAt
      erpCode
      deleted
      edited
      vatRate
      orderItemSubItemsId
      orderOrderItemsId
      orderItemProductId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.OrderItemByLocationQueryVariables, APITypes.OrderItemByLocationQuery>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    businessId
    locationId
    table {
      id
      businessId
      locationId
      floorTablesId
      name
      seats
      erpCode
      busy
      createdAt
      updatedAt
      tableSubTablesId
      tableActiveOrderId
      __typename
    }
    destination {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    note
    billingInfo {
      email
      phoneNumber
      firstName
      lastName
      companyName
      vatNumber
      sdi
      __typename
    }
    type
    orderItems {
      nextToken
      __typename
    }
    orderItemsSortOrder
    subTotal
    total
    paid
    deliverectParams {
      id
      type
      isAlreadyPaid
      paymentType
      paymentAmount
      decimalDigits
      status
      __typename
    }
    ristoQuickStatus
    status
    lastPaymentsRistoQuick {
      nextToken
      __typename
    }
    payments {
      nextToken
      __typename
    }
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      floorsSortOrder
      credit
      selfOrdering
      selfPayment
      unauthenticatedOrdering
      defaultOrderType
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      coverMainId
      erp
      erpMenuId
      deliveryCost
      generalVatRate
      businessLocationsId
      departmentsSortOrder
      invoicesAmount
      invoicesCount
      receiptsAmount
      receiptsCount
      createdAt
      updatedAt
      __typename
    }
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    userId
    user {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      gender
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    generalVatRate
    createdAt
    updatedAt
    deliveryCost
    phoneNumber
    instantPayment
    erpPrintMode
    orderTableId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $id: ID
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrders(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      createdAt
      updatedAt
      deliveryCost
      phoneNumber
      instantPayment
      erpPrintMode
      orderTableId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListOrdersQueryVariables, APITypes.ListOrdersQuery>;
export const orderByBusiness = /* GraphQL */ `query OrderByBusiness(
  $businessId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  orderByBusiness(
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      createdAt
      updatedAt
      deliveryCost
      phoneNumber
      instantPayment
      erpPrintMode
      orderTableId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.OrderByBusinessQueryVariables, APITypes.OrderByBusinessQuery>;
export const orderByLocation = /* GraphQL */ `query OrderByLocation(
  $locationId: ID!
  $ristoQuickStatusStatus: ModelOrderByLocationCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  orderByLocation(
    locationId: $locationId
    ristoQuickStatusStatus: $ristoQuickStatusStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      createdAt
      updatedAt
      deliveryCost
      phoneNumber
      instantPayment
      erpPrintMode
      orderTableId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.OrderByLocationQueryVariables, APITypes.OrderByLocationQuery>;
export const orderByUser = /* GraphQL */ `query OrderByUser(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  orderByUser(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      createdAt
      updatedAt
      deliveryCost
      phoneNumber
      instantPayment
      erpPrintMode
      orderTableId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.OrderByUserQueryVariables, APITypes.OrderByUserQuery>;
export const getPayment = /* GraphQL */ `query GetPayment($id: ID!) {
  getPayment(id: $id) {
    id
    orderID
    locationId
    orderIDRistoQuick
    status
    userID
    useCoin
    userEmail
    customerName
    paymentID
    paymentAmount
    lines {
      name
      quantity
      price
      vatRate
      __typename
    }
    paymentType
    customerInfoId
    customerInfoStamp
    invoiceID
    order {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      createdAt
      updatedAt
      deliveryCost
      phoneNumber
      instantPayment
      erpPrintMode
      orderTableId
      __typename
    }
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      floorsSortOrder
      credit
      selfOrdering
      selfPayment
      unauthenticatedOrdering
      defaultOrderType
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      coverMainId
      erp
      erpMenuId
      deliveryCost
      generalVatRate
      businessLocationsId
      departmentsSortOrder
      invoicesAmount
      invoicesCount
      receiptsAmount
      receiptsCount
      createdAt
      updatedAt
      __typename
    }
    documentType
    void
    voidID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPaymentQueryVariables, APITypes.GetPaymentQuery>;
export const listPayments = /* GraphQL */ `query ListPayments(
  $id: ID
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPayments(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      orderID
      locationId
      orderIDRistoQuick
      status
      userID
      useCoin
      userEmail
      customerName
      paymentID
      paymentAmount
      paymentType
      customerInfoId
      customerInfoStamp
      invoiceID
      documentType
      void
      voidID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPaymentsQueryVariables, APITypes.ListPaymentsQuery>;
export const paymentByLocation = /* GraphQL */ `query PaymentByLocation(
  $locationId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentByLocation(
    locationId: $locationId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderID
      locationId
      orderIDRistoQuick
      status
      userID
      useCoin
      userEmail
      customerName
      paymentID
      paymentAmount
      paymentType
      customerInfoId
      customerInfoStamp
      invoiceID
      documentType
      void
      voidID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.PaymentByLocationQueryVariables, APITypes.PaymentByLocationQuery>;
export const paymentByInvoice = /* GraphQL */ `query PaymentByInvoice(
  $invoiceID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentByInvoice(
    invoiceID: $invoiceID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderID
      locationId
      orderIDRistoQuick
      status
      userID
      useCoin
      userEmail
      customerName
      paymentID
      paymentAmount
      paymentType
      customerInfoId
      customerInfoStamp
      invoiceID
      documentType
      void
      voidID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.PaymentByInvoiceQueryVariables, APITypes.PaymentByInvoiceQuery>;
export const getIntegrationGeneral = /* GraphQL */ `query GetIntegrationGeneral($id: ID!) {
  getIntegrationGeneral(id: $id) {
    id
    latestVersion
    latestLink
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIntegrationGeneralQueryVariables,
  APITypes.GetIntegrationGeneralQuery
>;
export const listIntegrationGenerals = /* GraphQL */ `query ListIntegrationGenerals(
  $id: ID
  $filter: ModelIntegrationGeneralFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listIntegrationGenerals(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      latestVersion
      latestLink
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIntegrationGeneralsQueryVariables,
  APITypes.ListIntegrationGeneralsQuery
>;
export const getIntegration = /* GraphQL */ `query GetIntegration($id: ID!) {
  getIntegration(id: $id) {
    id
    run
    integrationGeneralId
    integrationGeneral {
      id
      latestVersion
      latestLink
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetIntegrationQueryVariables, APITypes.GetIntegrationQuery>;
export const listIntegrations = /* GraphQL */ `query ListIntegrations(
  $id: ID
  $filter: ModelIntegrationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listIntegrations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      run
      integrationGeneralId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListIntegrationsQueryVariables, APITypes.ListIntegrationsQuery>;
export const IntegrationbyIntegrationGeneralId =
  /* GraphQL */ `query IntegrationbyIntegrationGeneralId(
  $integrationGeneralId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelIntegrationFilterInput
  $limit: Int
  $nextToken: String
) {
  IntegrationbyIntegrationGeneralId(
    integrationGeneralId: $integrationGeneralId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      run
      integrationGeneralId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.IntegrationbyIntegrationGeneralIdQueryVariables,
    APITypes.IntegrationbyIntegrationGeneralIdQuery
  >;
export const getDepartment = /* GraphQL */ `query GetDepartment($id: ID!) {
  getDepartment(id: $id) {
    id
    businessId
    locationDepartmentId
    name
    nameTranslations {
      language
      body
      __typename
    }
    mainCategoriesIds
    excludedCategoriesIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDepartmentQueryVariables, APITypes.GetDepartmentQuery>;
export const listDepartments = /* GraphQL */ `query ListDepartments(
  $id: ID
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDepartments(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      businessId
      locationDepartmentId
      name
      mainCategoriesIds
      excludedCategoriesIds
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListDepartmentsQueryVariables, APITypes.ListDepartmentsQuery>;
export const departmentByBusiness = /* GraphQL */ `query DepartmentByBusiness(
  $businessId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  departmentByBusiness(
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationDepartmentId
      name
      mainCategoriesIds
      excludedCategoriesIds
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DepartmentByBusinessQueryVariables,
  APITypes.DepartmentByBusinessQuery
>;
export const departmentByLocation = /* GraphQL */ `query DepartmentByLocation(
  $locationDepartmentId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  departmentByLocation(
    locationDepartmentId: $locationDepartmentId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationDepartmentId
      name
      mainCategoriesIds
      excludedCategoriesIds
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DepartmentByLocationQueryVariables,
  APITypes.DepartmentByLocationQuery
>;
export const getCustomerInfo = /* GraphQL */ `query GetCustomerInfo($id: ID!) {
  getCustomerInfo(id: $id) {
    id
    businessId
    locationCustomerInfoId
    type
    email
    firstName
    lastName
    fiscalCode
    taxCode
    broadcastFormat
    businessName
    taxRegime
    address {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    sdi
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCustomerInfoQueryVariables, APITypes.GetCustomerInfoQuery>;
export const listCustomerInfos = /* GraphQL */ `query ListCustomerInfos(
  $id: ID
  $filter: ModelCustomerInfoFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCustomerInfos(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      businessId
      locationCustomerInfoId
      type
      email
      firstName
      lastName
      fiscalCode
      taxCode
      broadcastFormat
      businessName
      taxRegime
      sdi
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCustomerInfosQueryVariables, APITypes.ListCustomerInfosQuery>;
export const customerInfoByBusiness = /* GraphQL */ `query CustomerInfoByBusiness(
  $businessId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCustomerInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  customerInfoByBusiness(
    businessId: $businessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationCustomerInfoId
      type
      email
      firstName
      lastName
      fiscalCode
      taxCode
      broadcastFormat
      businessName
      taxRegime
      sdi
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CustomerInfoByBusinessQueryVariables,
  APITypes.CustomerInfoByBusinessQuery
>;
export const customerInfoByLocation = /* GraphQL */ `query CustomerInfoByLocation(
  $locationCustomerInfoId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCustomerInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  customerInfoByLocation(
    locationCustomerInfoId: $locationCustomerInfoId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      businessId
      locationCustomerInfoId
      type
      email
      firstName
      lastName
      fiscalCode
      taxCode
      broadcastFormat
      businessName
      taxRegime
      sdi
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CustomerInfoByLocationQueryVariables,
  APITypes.CustomerInfoByLocationQuery
>;
export const getUserPaymentMethods =
  /* GraphQL */ `query GetUserPaymentMethods($locationId: String) {
  getUserPaymentMethods(locationId: $locationId) {
    ... on GetUserPaymentMethodsResponseSuccess {
      userPaymentMethods {
        id
        brand
        last4
        default_source
        __typename
      }
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedQuery<any, APITypes.GetUserPaymentMethodsQuery>;
export const getLocationSubscription =
  /* GraphQL */ `query GetLocationSubscription($locationId: String) {
  getLocationSubscription(locationId: $locationId) {
    ... on GetLocationSubscriptionResponseSuccess {
      paymentMethods {
        id
        brand
        last4
        default_source
        __typename
      }
      subscription {
        id
        current_period_end
        schedule
        __typename
      }
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedQuery<
    APITypes.GetLocationSubscriptionQueryVariables,
    APITypes.GetLocationSubscriptionQuery
  >;
export const getLocationPrices = /* GraphQL */ `query GetLocationPrices {
  getLocationPrices {
    ... on GetLocationPricesResponseSuccess {
      prices {
        priceId
        __typename
      }
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedQuery<APITypes.GetLocationPricesQueryVariables, APITypes.GetLocationPricesQuery>;
export const getPresenceKey = /* GraphQL */ `query GetPresenceKey {
  getPresenceKey {
    ... on GetPresenceKeyResponseSuccess {
      presenceKey
      valid
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedQuery<any, APITypes.GetPresenceKeyQuery>;
