import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { getLocationQuery } from 'src/backend/queries';
import * as api from 'src/backend/API';
import * as mutations from 'src/backend/graphql/mutations';
import * as queries from 'src/backend/graphql/queries';

type Locations = {
  businessId: string;
  businessName: string;
  locationId: string;
  locationStatus: api.LocationStatus;
  locationName: string | null;
};

export async function getLocationsApi(locationids: string[]) {
  const tempLocations: Locations[] = [];
  try {
    for (const location of locationids) {
      // eslint-disable-next-line no-await-in-loop
      const getLocation = (await API.graphql(
        graphqlOperation(getLocationQuery, {
          id: location,
        })
      )) as {
        data: any;
      };

      if (!getLocation || !getLocation.data) throw new Error('No location found');

      tempLocations.push({
        businessName: getLocation.data.getLocation.business.name,
        businessId: getLocation.data.getLocation.business.id,
        locationId: getLocation.data.getLocation.id,
        locationStatus: getLocation.data.getLocation.status,
        locationName: getLocation.data.getLocation.name,
      });
    }

    return tempLocations;
  } catch (err) {
    console.log('err', err);

    return tempLocations;
  }
}

export async function getLocationMinimalApi(locationId: string) {
  try {
    const getLocationMinimalQuery = /* GraphQL */ `
      query GetLocation($id: ID!) {
        getLocation(id: $id) {
          id
          name
          business {
            name
            id
          }
          status
          erp
          stripeInfo {
            stripeAccount
            stripeCostumer
            stripeSubscription {
              id
              currentPeriodEnd
              status
              defaultPaymentMethod
              cancelAt
            }
          }
        }
      }
    `;

    const data = (await API.graphql(
      graphqlOperation(getLocationMinimalQuery, { id: locationId })
    )) as {
      data: any;
    };

    if (!data || !data.data || !data.data.getLocation) throw new Error('No location found');

    return data.data.getLocation;
  } catch (err) {
    return null;
  }
}

export async function getLocationApi(locationId: string) {
  try {
    const data = (await API.graphql(graphqlOperation(getLocationQuery, { id: locationId }))) as {
      data: any;
    };
    if (!data || !data.data || !data.data.getLocation) throw new Error('No menu found');

    return data.data.getLocation;
  } catch (err) {
    return null;
  }
}

export async function updateLocationApi(locationId: string, updateData: any) {
  try {
    const updateLocationQuery = await API.graphql<GraphQLQuery<any>>({
      query: mutations.updateLocation,
      variables: {
        input: {
          id: locationId,
          ...updateData,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (updateLocationQuery && updateLocationQuery.data) {
      return true;
    }
    throw new Error('Update error');
  } catch (err) {
    if (err && err.data && err.data.updateLocation && err.data.updateLocation.id) return true;
    console.log('err', err);
    return false;
  }
}

export async function updateLocationLanguagesApi(locationId: string, languages: string[]) {
  try {
    const updateLanguagesQuery = (await API.graphql({
      ...graphqlOperation(mutations.translateLocation, {
        locationID: locationId,
        languages,
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as any;

    console.log('updateLanguagesQuery', updateLanguagesQuery);
    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

export async function updateLocationStripeAccountApi(locationId: string, updateData: any) {
  try {
    const response = (await API.graphql({
      ...graphqlOperation(mutations.stripeUpdateAccount, {
        data: {
          locationId,
          ...updateData,
        },
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as {
      data: any;
    };

    if (
      !response ||
      !response.data ||
      !response.data.stripeUpdateAccount ||
      !response.data.stripeUpdateAccount.success
    )
      throw new Error(response.data.stripeUpdateAccount.error);
    return response;
  } catch (err) {
    if (err && err.data && err.data.updateLocation && err.data.updateLocation.id) return true;
    console.log('err', err);
    return null;
  }
}

export async function handleLocationStripeSubscriptionApi(locationId: string, type: api.Crud) {
  try {
    const response = (await API.graphql({
      ...graphqlOperation(mutations.stripeHandleCancelSubscription, {
        type,
        locationId,
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as any as {
      data: any;
    };

    return response;
  } catch (err) {
    if (err && err.data && err.data.updateLocation && err.data.updateLocation.id) return true;
    console.log('err', err);
    return null;
  }
}

export async function getLocationStripePaymentMethodApi(locationId: string) {
  try {
    const data = (await API.graphql({
      ...graphqlOperation(queries.getUserPaymentMethods, { locationId }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as any as {
      data: any;
    };
    console.log('data', data);

    if (!data || !data.data || !data.data.getUserPaymentMethods)
      throw new Error('No User payment methods found');

    return data.data.getUserPaymentMethods;
  } catch (err) {
    console.log('err2 ', err);
    return null;
  }
}

export async function handleLocationStripePaymentMethodApi(locationId: string, updateData: any) {
  try {
    console.log('locationId', locationId);
    console.log('updateData', updateData);
    const response = (await API.graphql({
      ...graphqlOperation(mutations.stripeHandlePaymentMethod, {
        locationId,
        ...updateData,
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as any as {
      data: any;
    };

    console.log('response', response);

    return response;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}
